import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    signal,
} from '@angular/core';
import type {ControlValueAccessor} from '@angular/forms';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import type {FunctionType, Nullish, SearchResultEntry} from '@dv/shared/code';
import {AggragatedEntitySearchTypes} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {SearchEntityResultComponent} from '../search-entity-result/search-entity-result.component';
import {SearchEntityComponent} from '../search-entity/search-entity.component';

@Component({
    selector: 'dv-search-list',
    imports: [
        TranslocoModule,
        SearchEntityComponent,
        SearchEntityResultComponent,
    ],
    providers: [
        {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SearchListComponent},
    ],
    templateUrl: './search-list.component.html',
    styleUrl: './search-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListComponent implements ControlValueAccessor {

    @Input({required: true}) public placeholder: string = '';
    @Input({required: true}) public entityToSearch!: AggragatedEntitySearchTypes;
    @Input() public entitiesToSearchFrom?: SearchResultEntry[];
    @Input() public orderByText?: boolean = false;

    @Input()
    public set readOnly(readOnly: boolean) {
        this.mutationAllowed.set(readOnly);
    }

    @Input({transform: (value: number) => isFinite(value) ? value : Infinity})
    public set maxItems(maxItems: number) {
        this._maxItems.set(maxItems);
    }

    @Output() public readonly add: EventEmitter<SearchResultEntry> = new EventEmitter();
    @Output() public readonly remove: EventEmitter<SearchResultEntry> = new EventEmitter();

    @HostBinding('class.highlight-input-fields-when-invalid')
    private readonly invalidInputHighlightClass: boolean = true;

    public mutationAllowed = signal(true);
    public isDisabled = signal(false);
    private _maxItems = signal(Infinity);
    private searchResultEntries = signal([] as SearchResultEntry[]);

    public showSearchInput = computed(() => {
        const belowMaxItemsLimit = this.searchResultEntries().length < this._maxItems();
        const editable = this.mutationAllowed();

        return belowMaxItemsLimit && editable;
    });

    public selectedEntries = computed(() => {
        const entries = this.searchResultEntries();

        return this.orderByText ?
            [...entries].sort((a, b) => a.text.localeCompare(b.text)) :
            entries;
    });

    public onChange?: FunctionType;
    public onTouched?: FunctionType;

    public registerOnChange(fn: FunctionType): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: FunctionType): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled.set(isDisabled);
    }

    public writeValue(entries: SearchResultEntry[]): void {
        this.searchResultEntries.set(entries);
    }

    public onSelect(item: SearchResultEntry | Nullish): void {
        if (!item || this.searchResultEntries().some(a => a.id === item.id)) {
            return;
        }

        this.searchResultEntries.update(entries => [...entries, item]);
        this.onChange?.(this.searchResultEntries());
        this.add.emit(item);
    }

    public onRemove(item: SearchResultEntry): void {
        this.searchResultEntries.update(entries => entries.filter(e => e !== item));
        this.onChange?.(this.searchResultEntries());
        this.remove.emit(item);
    }
}
