/**
 * Kita-Scheduler
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {Configuration} from '../configuration';
import {CustomHttpParameterCodec} from '../encoder';

import type {AdressenResourceGetMitbewohnerMitbewohnerParameter} from '@dv/shared/backend/model/adressen-resource-get-mitbewohner-mitbewohner-parameter';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {JaxAngestellteEditDay} from '@dv/shared/backend/model/jax-angestellte-edit-day';
import type {JaxAngestellteWorkDaysWithStandorte} from '@dv/shared/backend/model/jax-angestellte-work-days-with-standorte';
import type {JaxWorkTimeControlling} from '@dv/shared/backend/model/jax-work-time-controlling';
import type {MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter} from '@dv/shared/backend/model/mandant-rechnung-resource-get-mandant-rechnungen-mandantrechnungen-parameter';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';

export interface WorkTimeControllingServiceGetAllRequestParams {
    workTimeControlling: MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter;
}

export interface WorkTimeControllingServiceGetDaysForAngestellteRequestParams {
    angestellteId: EntityId;
    angestellteIdMatrix: MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter;
    kinderOrtId?: EntityId;
}

export interface WorkTimeControllingServiceGetForKinderOrtRequestParams {
    kinderOrtId: EntityId;
    kinderOrtIdMatrix: MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter;
}

export interface WorkTimeControllingServiceSaveManualEditRequestParams {
    angestellteId: EntityId;
    jaxAngestellteEditDay?: JaxAngestellteEditDay;
}

export interface WorkTimeControllingServiceStorePlannedAsActualRequestParams {
    kinderOrtId: EntityId;
    kinderOrtIdMatrix: AdressenResourceGetMitbewohnerMitbewohnerParameter;
}

@Injectable({
  providedIn: 'root'
})
export class WorkTimeControllingService {

    protected basePath = '/kitadmin';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string | string[],
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && !(value instanceof Date)) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    // @ts-ignore: Sometimes, this method is unused
    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                // noinspection AssignmentResultUsedJS
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                // noinspection AssignmentResultUsedJS
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getAll$(requestParameters: WorkTimeControllingServiceGetAllRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxWorkTimeControlling>;
    public getAll$(requestParameters: WorkTimeControllingServiceGetAllRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxWorkTimeControlling>>;
    public getAll$(requestParameters: WorkTimeControllingServiceGetAllRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxWorkTimeControlling>>;
    public getAll$(requestParameters: WorkTimeControllingServiceGetAllRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const workTimeControlling = requestParameters.workTimeControlling;
        if (workTimeControlling === null || workTimeControlling === undefined) {
            throw new Error('Required parameter workTimeControlling was null or undefined when calling getAll$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/work-time-controlling${this.configuration.encodeParam({name: "workTimeControlling", value: workTimeControlling, in: "path", style: "matrix", explode: true, dataType: "MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxWorkTimeControlling>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getDaysForAngestellte$(requestParameters: WorkTimeControllingServiceGetDaysForAngestellteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxAngestellteWorkDaysWithStandorte>;
    public getDaysForAngestellte$(requestParameters: WorkTimeControllingServiceGetDaysForAngestellteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxAngestellteWorkDaysWithStandorte>>;
    public getDaysForAngestellte$(requestParameters: WorkTimeControllingServiceGetDaysForAngestellteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxAngestellteWorkDaysWithStandorte>>;
    public getDaysForAngestellte$(requestParameters: WorkTimeControllingServiceGetDaysForAngestellteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const angestellteId = requestParameters.angestellteId;
        if (angestellteId === null || angestellteId === undefined) {
            throw new Error('Required parameter angestellteId was null or undefined when calling getDaysForAngestellte$.');
        }
        const angestellteIdMatrix = requestParameters.angestellteIdMatrix;
        if (angestellteIdMatrix === null || angestellteIdMatrix === undefined) {
            throw new Error('Required parameter angestellteIdMatrix was null or undefined when calling getDaysForAngestellte$.');
        }
        const kinderOrtId = requestParameters.kinderOrtId;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (kinderOrtId !== undefined && kinderOrtId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>kinderOrtId, 'kinderOrtId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/work-time-controlling/angestellte/${this.configuration.encodeParam({name: "angestellteId", value: angestellteId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}${this.configuration.encodeParam({name: "angestellteIdMatrix", value: angestellteIdMatrix, in: "path", style: "matrix", explode: true, dataType: "MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxAngestellteWorkDaysWithStandorte>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public getForKinderOrt$(requestParameters: WorkTimeControllingServiceGetForKinderOrtRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<JaxWorkTimeControlling>;
    public getForKinderOrt$(requestParameters: WorkTimeControllingServiceGetForKinderOrtRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<JaxWorkTimeControlling>>;
    public getForKinderOrt$(requestParameters: WorkTimeControllingServiceGetForKinderOrtRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<JaxWorkTimeControlling>>;
    public getForKinderOrt$(requestParameters: WorkTimeControllingServiceGetForKinderOrtRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling getForKinderOrt$.');
        }
        const kinderOrtIdMatrix = requestParameters.kinderOrtIdMatrix;
        if (kinderOrtIdMatrix === null || kinderOrtIdMatrix === undefined) {
            throw new Error('Required parameter kinderOrtIdMatrix was null or undefined when calling getForKinderOrt$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/work-time-controlling/kinder-orte/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}${this.configuration.encodeParam({name: "kinderOrtIdMatrix", value: kinderOrtIdMatrix, in: "path", style: "matrix", explode: true, dataType: "MandantRechnungResourceGetMandantRechnungenMandantrechnungenParameter", dataFormat: undefined})}`;
        return this.httpClient.request<JaxWorkTimeControlling>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public saveManualEdit$(requestParameters: WorkTimeControllingServiceSaveManualEditRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public saveManualEdit$(requestParameters: WorkTimeControllingServiceSaveManualEditRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public saveManualEdit$(requestParameters: WorkTimeControllingServiceSaveManualEditRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public saveManualEdit$(requestParameters: WorkTimeControllingServiceSaveManualEditRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const angestellteId = requestParameters.angestellteId;
        if (angestellteId === null || angestellteId === undefined) {
            throw new Error('Required parameter angestellteId was null or undefined when calling saveManualEdit$.');
        }
        const jaxAngestellteEditDay = requestParameters.jaxAngestellteEditDay;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/work-time-controlling/manual-times-edit/${this.configuration.encodeParam({name: "angestellteId", value: angestellteId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jaxAngestellteEditDay,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to
     *     returning the body.
     * @param reportProgress flag to report request and response progress.
     * @param options additional configuration of the HttpClient.
     */
    public storePlannedAsActual$(requestParameters: WorkTimeControllingServiceStorePlannedAsActualRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public storePlannedAsActual$(requestParameters: WorkTimeControllingServiceStorePlannedAsActualRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public storePlannedAsActual$(requestParameters: WorkTimeControllingServiceStorePlannedAsActualRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public storePlannedAsActual$(requestParameters: WorkTimeControllingServiceStorePlannedAsActualRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const kinderOrtId = requestParameters.kinderOrtId;
        if (kinderOrtId === null || kinderOrtId === undefined) {
            throw new Error('Required parameter kinderOrtId was null or undefined when calling storePlannedAsActual$.');
        }
        const kinderOrtIdMatrix = requestParameters.kinderOrtIdMatrix;
        if (kinderOrtIdMatrix === null || kinderOrtIdMatrix === undefined) {
            throw new Error('Required parameter kinderOrtIdMatrix was null or undefined when calling storePlannedAsActual$.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/v1/work-time-controlling/store-planned-times/${this.configuration.encodeParam({name: "kinderOrtId", value: kinderOrtId, in: "path", style: "simple", explode: false, dataType: "EntityId", dataFormat: "entity-id"})}${this.configuration.encodeParam({name: "kinderOrtIdMatrix", value: kinderOrtIdMatrix, in: "path", style: "matrix", explode: true, dataType: "AdressenResourceGetMitbewohnerMitbewohnerParameter", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

}
